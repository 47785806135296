import { MatDialogConfig } from '@angular/material/dialog'
import { MatFormFieldDefaultOptions } from '@angular/material/form-field'
import { MatSnackBarConfig } from '@angular/material/snack-bar'
import { ExtraOptions } from '@angular/router'
import { TranslocoConfig } from '@jsverse/transloco'
import { TranslocoLocaleConfig } from '@jsverse/transloco-locale'
import { MessageformatConfig } from '@jsverse/transloco-messageformat'
import { RouterState, StoreRouterConfig } from '@ngrx/router-store'
import { RootStoreConfig } from '@ngrx/store'
import { StoreDevtoolsOptions } from '@ngrx/store-devtools'
import {
  ActiveElement,
  ChartType,
  ChartTypeRegistry,
  Tooltip,
  TooltipItem,
  TooltipOptions,
  TooltipPositionerFunction,
} from 'chart.js'
import { DeepPartial } from 'chart.js/dist/types/utils'
import { isNil } from 'ramda'

import logger, { Level } from '~core/utils/logger'
import { environment } from '~env'
import { metaReducers, State } from '~store'

declare module 'chart.js' {
  interface TooltipPositionerMap {
    top: TooltipPositionerFunction<ChartType>
  }
}

Tooltip.positioners.top = function (items: ActiveElement[]) {
  const {
    chartArea: { top },
    scales: { x },
  } = this.chart
  if (items.length === 0) return false
  const pos = items[0].index
  return {
    x: x.getPixelForValue(pos),
    y: top,
    xAlign: 'center',
  }
}
export class AppConfig {
  static CHART_JS_LEGEND: any = {
    labels: {
      boxWidth: 10,
      usePointStyle: true,
      font: {
        family: 'Montserrat',
      },
      color: '#081b1e',
    },
  }

  static CHART_JS_TOOLTIPS: DeepPartial<TooltipOptions> = {
    enabled: true,
    backgroundColor: '#e8e8eb',
    titleColor: '#001f28',
    bodyColor: '#001f28',
    footerColor: '#001f28',
    borderWidth: 1,
    titleFont: { family: 'Montserrat' },
    bodyFont: { family: 'Montserrat', weight: 500 },
    footerFont: { family: 'Montserrat' },
    titleAlign: 'center',
    bodyAlign: 'left',
    footerAlign: 'center',
    borderColor: '#e8e8eb',
    cornerRadius: 10,
    padding: 16,
    position: 'nearest',
    caretSize: 0,
    usePointStyle: true,
    filter: (item: TooltipItem<keyof ChartTypeRegistry>) => !isNil(item.raw),
    callbacks: {
      label: function (tooltipItem: TooltipItem<keyof ChartTypeRegistry> | any): string | string[] {
        switch (tooltipItem.chart.config.type) {
          case 'pie':
          case 'doughnut':
          case 'polarArea':
            return ` ${tooltipItem.label}: ${tooltipItem.raw}`
          case 'choropleth':
            return ` ${tooltipItem.raw.feature.properties.name}: ${tooltipItem.raw.value}`
          case 'wordCloud':
            return tooltipItem.raw as string
          default:
            return ` ${tooltipItem.dataset.label}: ${tooltipItem.raw}`
        }
      },
    },
  }

  static DIALOG: MatDialogConfig = {
    hasBackdrop: true,
    width: '550px',
    maxWidth: '100%',
    closeOnNavigation: true,
  }

  static FORM_FIELD: MatFormFieldDefaultOptions = {
    appearance: 'outline',
  }

  static LOGGER: { level: Level } = {
    level: environment.production ? 'INFO' : 'DEBUG',
  }

  static ROUTER: ExtraOptions = {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 15],
  }

  static SNACKBAR: MatSnackBarConfig = {
    duration: 3000,
    verticalPosition: 'top',
    horizontalPosition: 'right',
  }

  static STORE: RootStoreConfig<State> = { metaReducers }

  static STORE_DEV_TOOLS: StoreDevtoolsOptions = { logOnly: environment.production }

  static STORE_ROUTER: StoreRouterConfig = {
    stateKey: 'router',
    routerState: RouterState.Minimal,
  }

  static TRANSLOCO: Partial<TranslocoConfig> = {
    availableLangs: ['en', 'it'],
    defaultLang: 'en',
    fallbackLang: 'en',
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: environment.production,
  }

  static TRANSLOCO_LOCALE: TranslocoLocaleConfig = {
    langToLocaleMapping: {
      en: 'en-US',
      it: 'it-IT',
    },
  }

  static TRANSLOCO_MESSAGE_FORMAT: MessageformatConfig = {
    locales: ['en-US', 'it-IT'],
  }
}
