import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common'
import { ChangeDetectionStrategy, Component, HostBinding, Input, computed, input } from '@angular/core'

import { CustomStyle } from '~common/types'

export type TextVariant =
  | 'headlineLarge'
  | 'headlineMedium'
  | 'headlineSmall'
  | 'title'
  | 'subtitle'
  | 'titleLarge'
  | 'titleMedium'
  | 'body'
  | 'bodyMedium'
  | 'labelLarge'
  | 'labelMedium'
  | 'label'
  | 'code'

@Component({
  selector: 'sb-text',
  template: `
    @switch (variant) {
      @case ('title') {
        <h1 [class]="classes" [ngClass]="ngClasses" [ngStyle]="customNgStyle()">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </h1>
      }
      @case ('subtitle') {
        <h2 [class]="classes" [ngClass]="ngClasses" [ngStyle]="customNgStyle()">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </h2>
      }
      @default {
        <p [class]="classes" [ngClass]="customNgClasses" [ngStyle]="customNgStyle()">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </p>
      }
    }

    <ng-template #content>
      @if (innerHTML) {
        <span [innerHtml]="innerHTML"></span>
      } @else {
        <ng-content></ng-content>
      }
    </ng-template>
  `,
  styles: [
    `
      @use 'sass:map';
      @use 'themes/config/palette';
      @use 'mixins';

      .no-margin {
        margin: 0;
      }

      .error {
        color: palette.$error-color;
      }

      .muted:not(.error) {
        color: map.get(palette.$neutral, 500);
      }

      .align-start {
        text-align: center;
      }

      .align-center {
        text-align: center;
      }

      .align-end {
        text-align: right;
      }

      .truncate {
        @include mixins.truncate-line();
      }

      .truncate-lines {
        // TODO refactor to handle variable number of lines
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        line-clamp: 5;
      }

      .mat-caption-medium {
        font-weight: 500;
      }

      .mat-caption-large {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }

      .mat-headline-1,
      .mat-typography .mat-headline-1,
      .mat-headline-2,
      .mat-typography .mat-headline-2,
      .mat-headline-3,
      .mat-typography .mat-headline-3 {
        margin: inherit;
      }

      .code {
        font-family: monospace;
        white-space: pre-wrap;
        background-color: map.get(palette.$neutral, 200);
        padding: 2px;
        border-radius: 4px;
        font-size: 14px;
      }

      .font-weight-400 {
        font-weight: 400 !important;
      }

      .font-weight-500 {
        font-weight: 500 !important;
      }

      .font-weight-600 {
        font-weight: 600 !important;
      }

      .font-weight-700 {
        font-weight: 700 !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, NgTemplateOutlet],
})
export class TextComponent {
  @Input() customStyle?: CustomStyle
  @Input() innerHTML: string
  @Input() variant: TextVariant = 'body'
  truncateLines = input<number | boolean>(false)
  private _classes = []
  private _error: boolean
  private _inline: boolean = false
  private _muted: boolean
  private _noMargin: boolean
  private _truncate: boolean
  private _weight: '400' | '500' | '600' | '700'
  @HostBinding('style.overflow') overflow: string

  customNgStyle = computed(() => {
    let style = this.customStyle
    if (typeof this.truncateLines() === 'number') {
      style = {
        ...style,
        '-webkit-line-clamp': this.truncateLines(),
        'line-clamp': this.truncateLines(),
      }
    }

    return style
  })

  @Input()
  set align(alignment: 'start' | 'center' | 'end') {
    this._classes.push(`text-${alignment}`)
  }

  get classes() {
    return this._classes.join(' ')
  }

  get customNgClasses() {
    const variant = this.variant
    return {
      ...this.ngClasses,
      'mat-headline-1': variant === 'headlineLarge',
      'mat-headline-2': variant === 'headlineMedium',
      'mat-headline-3': variant === 'headlineSmall',
      'mat-body-1': variant === 'body' || variant === 'code',
      'mat-body-2': variant === 'bodyMedium',
      'mat-subtitle-1': variant === 'titleLarge',
      'mat-subtitle-2': variant === 'titleMedium',
      'mat-caption': ['label', 'labelMedium', 'labelLarge'].includes(variant),
      'mat-caption-medium': variant === 'labelMedium',
      'mat-caption-large': variant === 'labelLarge',
      code: variant === 'code',
    }
  }

  @Input()
  get error() {
    return this._error
  }

  set error(value: BooleanInput) {
    this._error = coerceBooleanProperty(value)
  }

  get inline() {
    return this._inline
  }

  @Input()
  set inline(value: BooleanInput) {
    this._inline = coerceBooleanProperty(value)
  }

  @Input()
  get muted() {
    return this._muted
  }

  set muted(value: BooleanInput) {
    this._muted = coerceBooleanProperty(value)
  }

  get ngClasses() {
    return {
      'no-margin': this.noMargin || this.inline,
      error: this.error,
      muted: this.muted,
      inline: this.inline,
      truncate: !!this.truncate,
      'truncate-lines': !!this.truncateLines(),
      'font-weight-400': this._weight === '400',
      'font-weight-500': this._weight === '500',
      'font-weight-600': this._weight === '600',
      'font-weight-700': this._weight === '700',
    }
  }

  @Input()
  get noMargin() {
    return this._noMargin
  }

  set noMargin(value: BooleanInput) {
    this._noMargin = coerceBooleanProperty(value)
  }

  @Input()
  get truncate() {
    return this._truncate
  }

  set truncate(value: BooleanInput) {
    this._truncate = coerceBooleanProperty(value)
    if (this._truncate) {
      this.overflow = 'hidden'
    }
  }

  @Input()
  set weight(weight: '400' | '500' | '600' | '700') {
    this._weight = weight
  }
}
