import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { getBrowserLang } from '@jsverse/transloco'

import { environment } from '~env'

@Injectable({
  providedIn: 'root',
})
export class Helper {
  getPlatformLogoUrl(platform: string, variant = 'default') {
    switch (platform) {
      case 'facebook':
      case 'facebook-page':
        if (variant === 'white') {
          return '/assets/img/facebook-logo-white.png'
        }
        return '/assets/img/facebook-logo.png'
      case 'instagram':
      case 'instagram-account':
        if (variant === 'white') {
          return '/assets/img/instagram-logo-whitex200.png'
        }
        return '/assets/img/instagram-logox200.png'
      case 'facebook-ad-account':
        if (variant === 'white') {
          return '/assets/img/fb-ads-logo-white.png'
        }
        return '/assets/img/fb-ads-logo.png'
      case 'linkedin-organization':
        if (variant === 'white') {
          return '/assets/img/linkedin-logo-white.png'
        }
        return '/assets/img/linkedin-logo.png'
      case 'audience_network':
        return '/assets/img/audience-network-logo.svg'
      case 'messenger':
        return '/assets/img/messenger-logo.svg'
      case 'whatsapp':
        return '/assets/img/whatsapp-logo.svg'
    }
  }

  getPreferredLang() {
    const browserLang = getBrowserLang()
    if (!environment.availableLanguages.includes(browserLang)) {
      return environment.availableLanguages[0]
    }

    return browserLang
  }

  guessTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  removeQueryParamsFromRoute(route: ActivatedRoute) {
    return route.snapshot.pathFromRoot.map((o) => o.url[0]).join('/')
  }

  touchAllFormFields(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field)
      control.markAsTouched({ onlySelf: true })
    })
  }
}
