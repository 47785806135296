import { createReducer, on } from '@ngrx/store'

import * as CoreActions from './core.actions'

export const stateKey = 'core'

export interface State {
  isLoading: boolean
  isLoadingLocked: boolean
}

const initialState: State = {
  isLoading: false,
  isLoadingLocked: false,
}

const setIsLoading = (state, { payload }): State => {
  return state.isLoadingLocked
    ? state
    : {
        ...state,
        isLoading: payload,
      }
}

const setLockedIsLoading = (state, { payload }): State => ({
  ...state,
  isLoading: payload,
  isLoadingLocked: payload,
})

const setLockIsLoading = (state): State => ({
  ...state,
  isLoadingLocked: true,
})

export const reducer = createReducer(
  initialState,

  on(CoreActions.setIsLoading, setIsLoading),

  on(CoreActions.setLockedIsLoading, setLockedIsLoading),

  on(CoreActions.lockIsLoading, setLockIsLoading),
)
