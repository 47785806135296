import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { Component, HostBinding, Input } from '@angular/core'
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip'

@Component({
  selector: 'sb-tooltip',
  template: `
    <span
      class="inline-flex items-center overflow-hidden"
      [matTooltip]="content"
      [matTooltipPosition]="position"
      [matTooltipDisabled]="disabled"
    >
      <ng-content />
    </span>
  `,
  standalone: true,
  imports: [MatTooltipModule],
})
export class TooltipComponent {
  @Input() @HostBinding('style.display') display = 'inline-flex'
  @Input() content: string
  @Input() position: TooltipPosition = 'above'

  private _disabled = false

  @Input()
  get disabled() {
    return this._disabled
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
  }
}
