import { Pipe, PipeTransform } from '@angular/core'

import { getShortNumber } from '~core/utils/numbers'

@Pipe({
  name: 'shortNumber',
  standalone: true,
})
export class ShortNumberPipe implements PipeTransform {
  transform(number: number, args?: { explicitSign: boolean }): any {
    return getShortNumber(number, args?.explicitSign)
  }
}
