import { createFeatureSelector, createSelector } from '@ngrx/store'

import { ScreenSize, ScreenSizeOrder } from '~core/services/ui/screen.service'

import * as fromUI from './ui.reducer'

export const selectUIState = createFeatureSelector<fromUI.State>(fromUI.stateKey)

export const selectBreadcrumbs = createSelector(selectUIState, (state) => state.breadcrumbs)

export const selectScreenSize = createSelector(selectUIState, (state) => state.screenSize)

export const selectMobileMenuIsOpen = createSelector(selectUIState, (state) => state.mobileMenuIsOpen)

export const selectSidebar = createSelector(selectUIState, (state) => state.sidebar)

export const selectSidebarExpanded = createSelector(selectSidebar, (sidebar) => sidebar.expanded)

export const selectOpenDrawer = createSelector(selectUIState, ({ drawer }) => drawer.opened)

export const selectIsMobileSidebar = createSelector(selectScreenSize, (size: ScreenSize) => {
  let enabled = true
  switch (size) {
    case ScreenSize.Medium:
    case ScreenSize.Large:
    case ScreenSize.XLarge:
      enabled = false
      break
  }
  return enabled
})

export const selectIsScreenSizeGte = (breakpoint: ScreenSize) => {
  return createSelector(selectScreenSize, (size: ScreenSize) => {
    return ScreenSizeOrder.indexOf(size) >= ScreenSizeOrder.indexOf(breakpoint)
  })
}

export const selectIsScreenSizeLte = (breakpoint: ScreenSize) => {
  return createSelector(selectScreenSize, (size: ScreenSize) => {
    return ScreenSizeOrder.indexOf(size) <= ScreenSizeOrder.indexOf(breakpoint)
  })
}

export const selectThemeMode = createSelector(selectUIState, (state) => state.themeMode)

export const selectIsMobile = createSelector(selectScreenSize, (size: ScreenSize) => {
  let isMobile = false
  switch (size) {
    case ScreenSize.XSmall:
      isMobile = true
  }
  return isMobile
})
