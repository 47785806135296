import { Component, EventEmitter, Input, Output, booleanAttribute, input } from '@angular/core'
import { MatIcon } from '@angular/material/icon'

import { Icon, IconFill } from '~common/icons'

import { ButtonComponent } from '../button/button.component'

type AlertType = 'success' | 'warning' | 'error' | 'secondary' | 'neutral' | 'primary'
type MarginClassType = 'mb-0' | 'mb-1' | 'mb-2' | 'mb-3' | 'mb-4' | 'mb-5'

@Component({
  selector: 'sb-alert',
  template: `
    <div [class]="classes" role="alert">
      <div class="content">
        @if (icon()) {
          <mat-icon [svgIcon]="icon()" />
        }
        <div>
          <ng-content></ng-content>
        </div>
      </div>
      @if (dismissible()) {
        <sb-button variant="icon" [icon]="IconsFill.Close" (click)="onClick()" />
      }
    </div>
  `,
  styles: [':host { display: block; }'],
  standalone: true,
  imports: [ButtonComponent, MatIcon],
})
export class AlertComponent {
  @Output() dismiss: EventEmitter<void> = new EventEmitter<void>()
  @Input() marginClass: MarginClassType = 'mb-4'
  icon = input<Icon | IconFill>()
  variant = input<AlertType>('secondary')
  dismissible = input(false, { transform: booleanAttribute })
  protected readonly IconsFill = IconFill

  get classes(): string {
    return `alert alert-${this.variant()} ${this.marginClass}`
  }

  onClick(): void {
    this.dismiss.emit()
  }
}
