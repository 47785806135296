import dayjs from 'dayjs'

type Params = {
  since: string
  until: string
}

export interface DateRangeRequest {
  since: string
  until: string
}

export type ProjectDateRangeRequest = {
  projectId: string
} & DateRangeRequest

export class DateRange {
  since: dayjs.Dayjs
  until: dayjs.Dayjs

  constructor(since: dayjs.Dayjs, until: dayjs.Dayjs) {
    this.since = since
    this.until = until
  }

  get daysCount(): number {
    return this.until.endOf('day').diff(this.since.startOf('day'), 'days') + 1
  }

  get qsParams(): Params {
    return {
      since: this.sinceDate,
      until: this.untilDate,
    }
  }

  get sinceDate(): string {
    return this.since.format('YYYY-MM-DD')
  }

  get sinceUnix(): number {
    return this.since.unix()
  }

  get untilDate(): string {
    return this.until.format('YYYY-MM-DD')
  }

  get untilUnix(): number {
    return this.until.unix()
  }
}
