import { AppConfig } from '~core/config/config'

export type Level = 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR'

const levels = {
  DEBUG: { value: 1, func: 'log', icon: '🔎' },
  INFO: { value: 2, func: 'info', icon: '😎' },
  WARNING: { value: 3, func: 'warn', icon: '⚠' },
  ERROR: { value: 4, func: 'error', icon: '💣' },
}
const shouldLog = (level: Level) => levels[AppConfig.LOGGER.level].value <= levels[level].value
const log = (level: Level, ...args: unknown[]) => {
  // eslint-disable-next-line no-console
  console[levels[level].func](`${levels[level].icon ? levels[level].icon + ' ' : ''}[Sbam]`, ...args)
}

export default {
  debug: (...args: unknown[]): void => (shouldLog('DEBUG') ? log('DEBUG', ...args) : null),
  info: (...args: unknown[]): void => (shouldLog('INFO') ? log('INFO', ...args) : null),
  warning: (...args: unknown[]): void => (shouldLog('WARNING') ? log('WARNING', ...args) : null),
  error: (...args: unknown[]): void => (shouldLog('ERROR') ? log('ERROR', ...args) : null),
}
