import { Injectable } from '@angular/core'
import jwt_decode, { JwtPayload } from 'jwt-decode'

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private static TOKEN_KEY = 'accessToken'

  destroyToken() {
    window.localStorage.removeItem(JwtService.TOKEN_KEY)
  }

  getToken(): string {
    return window.localStorage.getItem(JwtService.TOKEN_KEY)
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode<JwtPayload>(token)

    if (decoded.exp === undefined) return null

    const date = new Date(0)
    date.setUTCSeconds(decoded.exp)
    return date
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken()
    if (!token) return true

    const date = this.getTokenExpirationDate(token)
    if (date === undefined) return false
    return !(date.valueOf() > new Date().valueOf())
  }

  saveToken(token: string) {
    window.localStorage.setItem(JwtService.TOKEN_KEY, token)
  }
}
