import { createSelector } from '@ngrx/store'
import * as R from 'ramda'

import { Channel } from '~features/projects/models/channel.model'

import { selectIntegrations } from '.'
import * as fromIntegrations from '../integration.reducer'

const selectFacebookAccountsObject = createSelector(
  selectIntegrations,
  (i: fromIntegrations.State) => i.facebookAccounts,
)

export const selectActiveFacebookAccountId = createSelector(
  selectFacebookAccountsObject,
  (facebookAccounts) => facebookAccounts.activeFacebookAccountId,
)

export const selectFacebookAccounts = createSelector(
  selectFacebookAccountsObject,
  (facebookAccounts) => facebookAccounts.list,
)

export const selectFacebookAccountsCount = createSelector(
  selectFacebookAccounts,
  (facebookAccounts) => facebookAccounts.length,
)

export const selectHasFacebookAccounts = createSelector(selectFacebookAccountsCount, (count) => count > 0)

const selectChannelsFromFacebookAccount = (a): Channel[] =>
  a.facebook_pages.concat(a.instagram_accounts, a.facebook_ad_accounts)

export const selectChannelsFromFacebookAccounts = createSelector(selectFacebookAccounts, (facebookAccounts) =>
  R.flatten(facebookAccounts.map(selectChannelsFromFacebookAccount)),
)

export const selectPartiallyUnauthorizedFacebookAccountIds = createSelector(
  selectChannelsFromFacebookAccounts,
  (channels) => channels.filter((c) => c.is_account_authorized && !c.is_authorized).map((c) => c.facebook_account_id),
)

export const selectFullyUnauthorizedFacebookAccounts = createSelector(selectFacebookAccounts, (facebookAccounts) =>
  facebookAccounts.filter((f) => !f.is_authorized),
)

export const selectHasUnautorizedFacebookAccounts = createSelector(
  selectFullyUnauthorizedFacebookAccounts,
  (facebookAccounts) => R.not(R.isEmpty(facebookAccounts)),
)

export const selectPartiallyUnauthorizedFacebookAccounts = createSelector(
  selectFacebookAccounts,
  selectPartiallyUnauthorizedFacebookAccountIds,
  (facebookAccounts, ids) => R.filter((f) => R.includes(f._id, ids), facebookAccounts),
)

export const selectAllUnauthorizedFacebookAccounts = createSelector(
  selectFullyUnauthorizedFacebookAccounts,
  selectPartiallyUnauthorizedFacebookAccounts,
  (fullyUnauthorized, partiallyUnauthorized) => fullyUnauthorized.concat(partiallyUnauthorized),
)

export const selectAuthorizedFacebookAccounts = createSelector(selectFacebookAccounts, (facebookAccounts) =>
  facebookAccounts.filter((f) => f.is_authorized),
)

export const selectHasAutorizedFacebookAccounts = createSelector(
  selectAuthorizedFacebookAccounts,
  (facebookAccounts) => !!facebookAccounts.length,
)

export const selectIsLoadingFacebookAccounts = createSelector(
  selectFacebookAccountsObject,
  (facebookAccounts) => facebookAccounts.isLoading,
)

export const selectFirstEligibleFacebookAccount = createSelector(
  selectFacebookAccounts,
  selectActiveFacebookAccountId,
  (facebookAccounts, activeFacebookAccountId) => {
    const activeFacebookAccount = facebookAccounts.find((f) => f._id === activeFacebookAccountId)
    return activeFacebookAccount && activeFacebookAccount.is_authorized
      ? activeFacebookAccount
      : facebookAccounts.find((f) => f.is_authorized) || null
  },
)

export const selectFirstEligibleFacebookAccountId = createSelector(
  selectFirstEligibleFacebookAccount,
  (facebookAccount) => facebookAccount._id,
)
