export const environment = {
  appUrl: 'https://app.sbam.io',
  production: true,
  stage: 'prod',
  availableLanguages: ['en', 'it'],
  apiEndpoint: 'https://api.sbam.io',
  v1: 'v1.0',
  facebookAppId: '274916432960939', // "Sbam" App Id
  facebookApiVersion: 'v18.0',
  facebookScopes: [
    'email',
    'pages_manage_ads',
    'pages_manage_metadata',
    'pages_read_engagement',
    'pages_read_user_content',
    'read_insights',
    'instagram_basic',
    'instagram_manage_insights',
    'ads_read',
    'ads_management',
    'business_management',
    'instagram_manage_comments',
  ],
  linkedinAppKey: '77rwy4jtabe5rx',
  linkedinScopes: [
    'r_organization_social',
    'r_1st_connections_size',
    'r_ads_reporting',
    'r_emailaddress',
    'rw_organization_admin',
    'r_basicprofile',
    'r_ads',
  ],
  rollbarAccessToken: '9e637e82568f44de92a8d1a519083a60',
  forceUpdate: false,
  maintenance: false,
  stripePublicKey: 'pk_live_F5iYActrdZe84fKqbdy76X3j00UQiGYZTo',
  mauticPublicKey: '3_pzuzclmzzzksowok00wc4488w00k0c00k0sgo4s0oko8wok00',
  pusherAppKey: 'sbam_websockets_key',
  pusherAppCluster: 'mt1',
  wsEnabled: true,
  wsHost: 'websockets.sbam.io',
  wsPort: 443,
}
