import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { environment } from '~env'

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  delete(path): Observable<any> {
    return this.http.delete(`${environment.apiEndpoint}${path}`).pipe(catchError(this.formatErrors))
  }

  get(path: string, params: HttpParams = new HttpParams(), options: {} = {}): Observable<any> {
    return this.http
      .get(`${environment.apiEndpoint}${path}`, { params, ...options })
      .pipe(catchError(this.formatErrors))
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http
      .patch(`${environment.apiEndpoint}${path}`, JSON.stringify(body))
      .pipe(catchError(this.formatErrors))
  }

  post(
    path: string,
    body: Object = {},
    contentType: 'application/json' | 'multipart/form-data' = 'application/json',
  ): Observable<any> {
    let options = {}
    if (contentType === 'application/json') {
      options = {
        headers: {
          'Content-Type': contentType,
        },
      }
    }

    return this.http
      .post(
        `${environment.apiEndpoint}${path}`,
        contentType === 'application/json' ? JSON.stringify(body) : body,
        options,
      )
      .pipe(catchError(this.formatErrors))
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${environment.apiEndpoint}${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors))
  }

  private formatErrors = (error: any) => {
    // todo improve error management with global error handler or implement logging
    // let message = '';
    /*if (error.error.errors && Object.getOwnPropertyNames(errorMessages.errors).length > 0) {
      title = this.translateService.translate(errorMessages.message);
      message = '<ul>';
      for (let errNames of Object.getOwnPropertyNames(errorMessages.errors)) {
        message += '<li>' + errorMessages.errors[errNames].join('</li><li>') + '</li>';
      }
      message += '</ul>';
    } else {
      message = error.error.message;
    }*/

    return throwError(error)
  }
}
