import { Component, HostBinding, Input, OnInit, booleanAttribute, effect, input, untracked } from '@angular/core'

type BaseCss = 'inherit' | 'initial' | 'unset'

type Position = 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky' | BaseCss
type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse' | BaseCss
type FlexAlignItems = 'normal' | 'flex-start' | 'flex-end' | 'center' | string | BaseCss
type FlexJustifyContent = FlexAlignItems | 'left' | 'right' | 'space-between' | 'space-around' | 'space-evenly' | string
type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse' | BaseCss
type FlexGrow = number | BaseCss | string
type FlexShrink = number | BaseCss

@Component({
  selector: 'sb-box',
  template: '<ng-content></ng-content>',
  styles: [':host{ display: flex; }'],
  standalone: true,
})
export class BoxComponent implements OnInit {
  @Input() @HostBinding('style.alignItems') items: FlexAlignItems = 'start'
  @Input() @HostBinding('style.flexDirection') direction: FlexDirection = 'column'
  @Input() @HostBinding('style.flexGrow') grow: FlexGrow = 0
  @Input() @HostBinding('style.height') height = 'auto'
  @Input() @HostBinding('style.justifyContent') justify: FlexJustifyContent = 'start'
  @Input() @HostBinding('style.margin') margin: string
  @Input() @HostBinding('style.maxWidth') maxWidth: string
  @Input() @HostBinding('style.minWidth') minWidth: string
  @Input() @HostBinding('style.padding') pad: string
  @Input() @HostBinding('style.position') position: Position = 'static'
  @Input() @HostBinding('style.flexShrink') shrink: FlexShrink = 1
  @Input() @HostBinding('style.top') top = '0'
  @Input() @HostBinding('style.width') width = 'auto'
  @Input() @HostBinding('style.flexWrap') wrap: FlexWrap = 'nowrap'
  @Input() @HostBinding('style.gap') gap: string
  @Input() @HostBinding('style.overflow') overflow: string = 'hidden'

  fill = input(false, { transform: booleanAttribute })

  ngOnInit() {
    if (this.fill()) {
      this.width = '100%'
    }
  }
}
