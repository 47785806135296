import { createFeatureSelector, createSelector } from '@ngrx/store'

import * as fromIntegrations from '~features/integrations/store/integration.reducer'

export const selectIntegrations = createFeatureSelector<fromIntegrations.State>(fromIntegrations.stateKey)

export const selectIsOpenAddChannel = createSelector(
  selectIntegrations,
  (integrations: fromIntegrations.State) => integrations.isOpenAddChannel,
)

export const selectIntegrationErrors = createSelector(
  selectIntegrations,
  (integrations: fromIntegrations.State) => integrations.error,
)
