import { BreakpointObserver, Breakpoints as Mat2Breakpoints } from '@angular/cdk/layout'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

export enum ScreenSize {
  XSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
}

export const ScreenSizeOrder = [
  ScreenSize.XSmall,
  ScreenSize.Small,
  ScreenSize.Medium,
  ScreenSize.Large,
  ScreenSize.XLarge,
]

export enum DeviceType {
  Handset = 'Handset',
  Tablet = 'Tablet',
  Web = 'Web',
}

export enum ScreenOrientation {
  HandsetPortrait = 'HandsetPortrait',
  TabletPortrait = 'TabletPortrait',
  WebPortrait = 'WebPortrait',
  HandsetLandscape = 'HandsetLandscape',
  TabletLandscape = 'TabletLandscape',
  WebLandscape = 'WebLandscape',
}

export const Breakpoints = {
  [ScreenSize.XSmall]: Mat2Breakpoints.XSmall,
  [ScreenSize.Small]: '(min-width: 600px) and (max-width: 839.98px)',
  [ScreenSize.Medium]: '(min-width: 840px) and (max-width: 1279.98px)',
  [ScreenSize.Large]: Mat2Breakpoints.Large,
  [ScreenSize.XLarge]: Mat2Breakpoints.XLarge,
}

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  deviceType: Observable<DeviceType>
  orientation: Observable<ScreenOrientation>
  size: Observable<ScreenSize>
  private deviceTypeSubject: BehaviorSubject<DeviceType> = new BehaviorSubject<DeviceType>(DeviceType.Web)
  private orientationSubject: BehaviorSubject<ScreenOrientation> = new BehaviorSubject<ScreenOrientation>(
    ScreenOrientation.WebLandscape,
  )
  private sizeSubject: BehaviorSubject<ScreenSize> = new BehaviorSubject<ScreenSize>(ScreenSize.Large)

  constructor(private breakpointObserver: BreakpointObserver) {
    this.setupBreakpointObservable()
  }

  private getDeviceType(breakpoints: { [p: string]: boolean }): DeviceType {
    if (breakpoints[Mat2Breakpoints.HandsetPortrait] || breakpoints[Mat2Breakpoints.HandsetLandscape]) {
      return DeviceType.Handset
    } else if (breakpoints[Mat2Breakpoints.TabletPortrait] || breakpoints[Mat2Breakpoints.TabletLandscape]) {
      return DeviceType.Tablet
    } else if (breakpoints[Mat2Breakpoints.WebPortrait] || breakpoints[Mat2Breakpoints.WebLandscape]) {
      return DeviceType.Web
    }
  }

  private getOrientation(breakpoints: { [p: string]: boolean }): ScreenOrientation {
    if (breakpoints[Mat2Breakpoints.HandsetPortrait]) {
      return ScreenOrientation.HandsetPortrait
    } else if (breakpoints[Mat2Breakpoints.TabletPortrait]) {
      return ScreenOrientation.TabletPortrait
    } else if (breakpoints[Mat2Breakpoints.WebPortrait]) {
      return ScreenOrientation.WebPortrait
    } else if (breakpoints[Mat2Breakpoints.HandsetLandscape]) {
      return ScreenOrientation.HandsetLandscape
    } else if (breakpoints[Mat2Breakpoints.TabletLandscape]) {
      return ScreenOrientation.TabletLandscape
    } else if (breakpoints[Mat2Breakpoints.WebLandscape]) {
      return ScreenOrientation.WebLandscape
    }
  }

  private getScreenSize(breakpoints: { [p: string]: boolean }): ScreenSize {
    if (breakpoints[Breakpoints.XSmall]) {
      return ScreenSize.XSmall
    } else if (breakpoints[Breakpoints.Small]) {
      return ScreenSize.Small
    } else if (breakpoints[Breakpoints.Medium]) {
      return ScreenSize.Medium
    } else if (breakpoints[Breakpoints.Large]) {
      return ScreenSize.Large
    } else if (breakpoints[Breakpoints.XLarge]) {
      return ScreenSize.XLarge
    }
  }

  private setupBreakpointObservable(): void {
    this.size = this.sizeSubject.asObservable()
    this.orientation = this.orientationSubject.asObservable()
    this.deviceType = this.deviceTypeSubject.asObservable()
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Mat2Breakpoints.Tablet,
        Mat2Breakpoints.Handset,
        Mat2Breakpoints.Web,
      ])
      .subscribe((result) => {
        this.sizeSubject.next(this.getScreenSize(result.breakpoints))
        this.orientationSubject.next(this.getOrientation(result.breakpoints))
        this.deviceTypeSubject.next(this.getDeviceType(result.breakpoints))
      })
  }
}
