import { LinkedinOrganization } from '~features/linkedin-organization/models/linkedin-organization.model'
import { Channel } from '~features/projects/models/channel.model'

export class Project {
  _id: string
  channels: Channel[]
  createdAt: Date
  emails: string[]
  facebookAdAccounts: Channel[]
  facebookPages: Channel[]
  imgType?: string
  instagramAccounts: Channel[]
  is_summary_enabled: boolean
  name: string
  picture: string
  summary_frequency: 'weekly' | 'monthly'
  website: string

  constructor(project = null) {
    this.channels = []
    if (!!project) {
      Object.assign(this, project)
    }
  }

  get linkedinOrganization(): Channel {
    return this.getChannelByType('linkedin-organization')
  }

  get facebookAdAccount(): Channel {
    return this.getChannelByType('facebook-ad-account')
  }

  get facebookPage(): Channel {
    return this.getChannelByType('facebook-page')
  }

  get instagramAccount(): Channel {
    return this.getChannelByType('instagram-account')
  }

  getChannelById(_id: string): Channel | LinkedinOrganization {
    return this.channels.find((channel) => channel._id === _id)
  }

  getChannelByType(type: string): Channel {
    return this.channels.find((channel) => {
      // Backward compatibility
      switch (type) {
        case 'instagram-account':
          type = 'instagram'
          break
        case 'facebook-page':
          type = 'facebook'
          break
      }

      return channel.type === type
    })
  }

  getChannelIndexById(_id: string): number {
    return this.channels.findIndex((channel) => channel._id === _id)
  }

  getChannelTypes(): string[] {
    const channelTypes = []
    if (this.hasChannels()) {
      this.channels.forEach((channel) => {
        if (!channelTypes.includes(channel.type)) {
          channelTypes.push(channel.type)
        }
      })
    }
    return channelTypes
  }

  getChannelsByType(type: string): Channel[] {
    return this.channels.filter((channel) => {
      // Backward compatibility
      switch (type) {
        case 'instagram-account':
          type = 'instagram'
          break
        case 'facebook-page':
          type = 'facebook'
          break
      }

      return channel.type === type
    })
  }

  getFacebookAdAccounts(): Channel[] {
    return this.getChannelsByType('facebook-ad-account')
  }

  getFacebookPages(): Channel[] {
    return this.getChannelsByType('facebook')
  }

  getInstagramAccounts(): Channel[] {
    return this.getChannelsByType('instagram')
  }

  hasChannels(): boolean {
    return this.channels && this.channels.length > 0
  }

  hasFacebookAdAccounts(): boolean {
    return this.getFacebookAdAccounts().length > 0
  }

  hasFacebookPages(): boolean {
    return this.getFacebookPages().length > 0
  }

  hasInstagramAccounts(): boolean {
    return this.getInstagramAccounts().length > 0
  }
}
