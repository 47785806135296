import { routerReducer, RouterState } from '@ngrx/router-store'
import { ActionReducerMap, MetaReducer } from '@ngrx/store'

import * as fromCore from '~core/store/core.reducer'
import * as fromUI from '~core/store/ui/ui.reducer'
import { environment } from '~env'
import * as fromIntegrations from '~features/integrations/store/integration.reducer'

export interface State {
  core: fromCore.State
  router: RouterState
  ui: fromUI.State
  integrations: fromIntegrations.State
}

export const reducers: ActionReducerMap<State> = {
  core: fromCore.reducer,
  router: routerReducer,
  ui: fromUI.reducer,
  integrations: fromIntegrations.integrationReducer,
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : []
