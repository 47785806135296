import { createAction, props } from '@ngrx/store'

import { Breadcrumb } from '~core/models/breadcrumb.model'
import { ScreenSize } from '~core/services/ui/screen.service'

export const setBreadcrumbs = createAction('[UI] Set breadcrumbs', props<{ payload: Breadcrumb[] }>())

export const setScreenSize = createAction('[UI] Set screen size', props<{ payload: ScreenSize }>())

export const toggleMobileMenu = createAction('[UI] Toggle mobile menù')

export const closeMobileMenu = createAction('[UI] Close mobile menù')

export const toggleExpandSidebar = createAction('[UI] Toggle expand sidebar')

export const setExpandedSidebar = createAction('[UI] Set expand sidebar', props<{ payload: boolean }>())

export const toggleThemeMode = createAction('[UI] Toggle theme mode')

export const setOpenDrawer = createAction('[UI] Set open drawer', props<{ payload: boolean }>())
