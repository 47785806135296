import { createReducer, on } from '@ngrx/store'

import { Breadcrumb } from '~core/models/breadcrumb.model'
import { ScreenSize } from '~core/services/ui/screen.service'

import * as UIActions from './ui.actions'

export type ThemeMode = 'light' | 'dark'

export const stateKey = 'ui'

export interface State {
  breadcrumbs: Breadcrumb[]
  mobileMenuIsOpen: boolean
  screenSize: ScreenSize
  sidebar: { expanded: boolean; expandedSize: string; collapsedSize: string }
  themeMode: ThemeMode
  drawer: { opened: boolean }
}

const initialState: State = {
  breadcrumbs: [],
  mobileMenuIsOpen: false,
  sidebar: { expanded: false, expandedSize: '220px', collapsedSize: '72px' },
  screenSize: null,
  themeMode: 'light', // todo: get from storage
  drawer: { opened: false },
}

const setBreadcrumbs = (state: State, { payload }): State => ({
  ...state,
  breadcrumbs: payload,
})

const setScreenSize = (state: State, { payload }: { payload: ScreenSize }): State => {
  return {
    ...state,
    screenSize: payload,
  }
}

const toggleThemeMode = (state: State): State => ({
  ...state,
  themeMode: state.themeMode === 'light' ? 'dark' : 'light',
})

const toggleMobileMenu = (state: State): State => ({
  ...state,
  mobileMenuIsOpen: !state.mobileMenuIsOpen,
})

const closeMobileMenu = (state: State): State => ({
  ...state,
  mobileMenuIsOpen: false,
})

const toggleSidebar = (state: State): State => ({
  ...state,
  sidebar: {
    ...state.sidebar,
    expanded: !state.sidebar.expanded,
  },
})

const setExpandedSidebar = (state: State, { payload }: { payload: boolean }): State => ({
  ...state,
  sidebar: {
    ...state.sidebar,
    expanded: payload,
  },
})

const setOpenDrawer = (state: State, { payload }: { payload: boolean }): State => ({
  ...state,
  drawer: {
    ...state.drawer,
    opened: payload,
  },
})

export const reducer = createReducer(
  initialState,

  on(UIActions.setBreadcrumbs, setBreadcrumbs),

  on(UIActions.setScreenSize, setScreenSize),

  on(UIActions.toggleMobileMenu, toggleMobileMenu),

  on(UIActions.closeMobileMenu, closeMobileMenu),

  on(UIActions.toggleExpandSidebar, toggleSidebar),

  on(UIActions.setExpandedSidebar, setExpandedSidebar),

  on(UIActions.toggleThemeMode, toggleThemeMode),

  on(UIActions.setOpenDrawer, setOpenDrawer),
)
