import { NgClass, NgStyle } from '@angular/common'
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core'

export type AvatarSize = 'xxs' | 'xs' | 'sm' | 'lg' | 'xl' | 'xxl'

@Component({
  selector: 'sb-avatar',
  template: `
    <div
      class="avatar"
      [ngClass]="{
        'avatar-xxs': size === 'xxs',
        'avatar-xs': size === 'xs',
        'avatar-sm': size === 'sm',
        'avatar-lg': size === 'lg',
        'avatar-xl': size === 'xl',
        'avatar-xxl': size === 'xxl'
      }"
    >
      @if (!imageSrcError) {
        <img (error)="onError()" [alt]="title" class="avatar-img" [src]="src" />
      }
      @if (imageSrcError) {
        <div class="rounded-circle flex">
          @if (initials) {
            <svg width="100%" height="100%" viewBox="0 0 100 100">
              <circle cx="50%" cy="50%" r="50" fill="#b7d0d6" />
              <text
                x="50"
                y="73"
                text-anchor="middle"
                textLength="75%"
                fill="#06667c"
                [ngStyle]="{ fontSize: '60px', fontWeight: 600, fontFamily: 'monospace' }"
              >
                {{ initials }}
              </text>
            </svg>
          }
        </div>
        @if (!initials) {
          <img class="avatar-img rounded-circle" [src]="missingPic" />
        }
      }
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      @use 'variables' as var;
      @use 'sass:math';

      // Avatar

      // General

      .avatar {
        position: relative;
        display: block;
        width: var.$avatar-size-base;
        height: var.$avatar-size-base;
        font-size: math.div(var.$avatar-size-base, 3);
        user-select: none;

        // Loads mask images so they don't lag on hover

        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          background-image: url(#{var.$path-to-img}/masks/avatar/avatar-status.svg),
            url(#{var.$path-to-img}/masks/avatar/avatar-group.svg),
            url(#{var.$path-to-img}/masks/avatar/avatar-group-hover.svg),
            url(#{var.$path-to-img}/masks/avatar/avatar-group-hover-last.svg);
        }
      }

      .avatar-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid var.$avatar-border-color;
      }

      .avatar-title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        //background-color: $avatar-title-bg;
        //color: $avatar-title-color;
      }

      // Status

      .avatar-online,
      .avatar-offline {
        &::before {
          content: '';
          position: absolute;
          bottom: 5%;
          right: 5%;
          width: 20%;
          height: 20%;
          border-radius: 50%;
        }

        .avatar-img {
          mask-image: url(#{var.$path-to-img}/masks/avatar/avatar-status.svg);
          mask-size: 100% 100%;
        }
      }

      .avatar-online::before {
        //background-color: $success;
      }

      .avatar-offline::before {
        //background-color: $gray-500;
      }

      // Sizing

      .avatar-xxs {
        width: var.$avatar-size-xxs;
        height: var.$avatar-size-xxs;
        font-size: math.div(var.$avatar-size-xxs, 3);
      }

      .avatar-xs {
        width: var.$avatar-size-xs;
        height: var.$avatar-size-xs;
        font-size: math.div(var.$avatar-size-xs, 3);
      }

      .avatar-sm {
        width: var.$avatar-size-sm;
        height: var.$avatar-size-sm;
        font-size: math.div(var.$avatar-size-sm, 3);
      }

      .avatar-lg {
        width: var.$avatar-size-lg;
        height: var.$avatar-size-lg;
        font-size: math.div(var.$avatar-size-lg, 3);
      }

      .avatar-xl {
        width: var.$avatar-size-xl;
        height: var.$avatar-size-xl;
        font-size: math.div(var.$avatar-size-xl, 3);
      }

      .avatar-xxl {
        width: var.$avatar-size-xxl;
        height: var.$avatar-size-xxl;
        font-size: math.div(var.$avatar-size-xxl, 3);
        //
        //@include media-breakpoint-up(md) {
        //  width: var.$avatar-size-xxl;
        //  height: var.$avatar-size-xxl;
        //  font-size: math.div(var.$avatar-size-xxl, 3);
        //}
      }

      // Ratio

      .avatar.avatar-4by3 {
        width: var.$avatar-size-base * math.div(4, 3);
      }

      .avatar-xs.avatar-4by3 {
        width: var.$avatar-size-xs * math.div(4, 3);
      }

      .avatar-sm.avatar-4by3 {
        width: var.$avatar-size-sm * math.div(4, 3);
      }

      .avatar-lg.avatar-4by3 {
        width: var.$avatar-size-lg * math.div(4, 3);
      }

      .avatar-xl.avatar-4by3 {
        width: var.$avatar-size-xl * math.div(4, 3);
      }

      .avatar-xxl.avatar-4by3 {
        width: var.$avatar-size-xxl * math.div(4, 3);
      }

      // Group

      .avatar-group {
        display: inline-flex;

        // Shift every next avatar left

        .avatar + .avatar {
          margin-left: -(math.div(var.$avatar-size-base, 4));
        }

        .avatar-xs + .avatar-xs {
          margin-left: -(math.div(var.$avatar-size-xs, 4));
        }

        .avatar-sm + .avatar-sm {
          margin-left: -(math.div(var.$avatar-size-sm, 4));
        }

        .avatar-lg + .avatar-lg {
          margin-left: -(math.div(var.$avatar-size-lg, 4));
        }

        .avatar-xl + .avatar-xl {
          margin-left: -(math.div(var.$avatar-size-xl, 4));
        }

        .avatar-xxl + .avatar-xxl {
          margin-left: -(math.div(var.$avatar-size-xxl, 4));
        }

        // Add some spacing between avatars

        .avatar:not(:last-child) {
          mask-image: url(#{var.$path-to-img}/masks/avatar/avatar-group.svg);
          mask-size: 100% 100%;
        }

        // Bring an avatar to front on hover

        .avatar:hover {
          mask-image: none;
          z-index: 1;

          + .avatar {
            mask-image: url(#{var.$path-to-img}/masks/avatar/avatar-group-hover.svg);
            mask-size: 100% 100%;

            &:last-child {
              mask-image: url(#{var.$path-to-img}/masks/avatar/avatar-group-hover-last.svg);
            }
          }
        }
      }

      svg {
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle],
})
export class AvatarComponent {
  imageSrcError = false
  initials: string
  missingPic = '/assets/img/avatars/social-placeholder.jpg'
  @Input() size: AvatarSize = null
  @Input() title: string = ''
  private _src: string

  @HostBinding('class') get classes() {
    return this.size ? `avatar-${this.size}` : null
  }

  @Input()
  set src(value: string) {
    this.imageSrcError = false
    this._src = value
  }

  get src(): string {
    return this._src
  }

  onError() {
    this.calculateInitials()
    this.imageSrcError = true
  }

  private calculateInitials() {
    const parts = this.title
      .replace(/[^a-z0-9\s]/gi, '')
      .replace(/\s\s+/g, ' ')
      .split(' ')
      .slice(0, 2)
    this.initials = parts.map((part) => part.substring(0, 1).toUpperCase()).join('')
  }
}
