import { Directive, HostListener } from '@angular/core'

@Directive({
  selector: '[sbClickStopPropagation]',
  standalone: true,
})
export class ClickStopPropagationDirective {
  @HostListener('click', ['$event'])
  @HostListener('sbClick', ['$event'])
  public onClick(event: any): void {
    event.preventDefault()
    event.stopPropagation()
  }
}
