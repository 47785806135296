import { NgStyle, TitleCasePipe } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

import { IconFill } from '~icons'

import { RemoveUnderscoresPipe } from '../../pipes/remove-underscores.pipe'
import { BoxComponent } from '../../ui/box/box.component'

@Component({
  selector: 'sb-channel-icons',
  template: `
    @if (channelTypes?.length > 0) {
      <sb-box direction="row" gap="6px">
        @for (channelType of channelTypes; track channelType) {
          <mat-icon
            class="icon"
            [ngStyle]="{ width: iconSize, height: iconSize }"
            [svgIcon]="channelIconsMap[channelType]"
            [title]="channelType | titlecase | removeUnderscores"
          />
        }
      </sb-box>
    }
  `,
  styles: [],
  standalone: true,
  imports: [BoxComponent, MatIconModule, NgStyle, TitleCasePipe, RemoveUnderscoresPipe],
})
export class ChannelIconsComponent {
  @Input() channelTypes: string[] = []
  @Input() iconSize: string = '18px'

  channelIconsMap = {
    facebook: IconFill.Facebook,
    'facebook-ad-account': IconFill.Campaign,
    'facebook-page': IconFill.Facebook,
    instagram: IconFill.Instagram,
    'instagram-account': IconFill.Instagram,
    'linkedin-organization': IconFill.Linkedin,
    audience_network: IconFill.AudienceNetwork,
    messenger: IconFill.FacebookMessenger,
    whatsapp: IconFill.Whatsapp,
  }
}
