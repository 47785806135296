import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private matSnackbar: MatSnackBar) {}

  dismiss(): void {
    this.matSnackbar.dismiss()
  }

  error(text: string, action = 'OK', config: Omit<MatSnackBarConfig, 'panelClass'> = null) {
    return this.matSnackbar.open(text, action, { ...config, panelClass: 'snackbar-error' })
  }

  open(text: string, action = 'OK', config: MatSnackBarConfig = null) {
    return this.matSnackbar.open(text, action, config)
  }

  openFromComponent(component) {
    return this.matSnackbar.openFromComponent(component)
  }

  success(text: string, action = 'OK', config: Omit<MatSnackBarConfig, 'panelClass'> = null) {
    return this.matSnackbar.open(text, action, { ...config, panelClass: 'snackbar-success' })
  }

  warning(text: string, action = 'OK', config: Omit<MatSnackBarConfig, 'panelClass'> = null) {
    return this.matSnackbar.open(text, action, { ...config, panelClass: 'snackbar-warning' })
  }
}
