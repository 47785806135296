import { HttpErrorResponse } from '@angular/common/http'
import { Action, createReducer, on } from '@ngrx/store'

import { FacebookAccount } from '~features/integrations/models/facebook-account.model'
import { LinkedinAccount } from '~features/integrations/models/linkedin-account.model'

import * as IntegrationActions from './actions'

export const stateKey = 'integrations'

export interface State {
  facebookAccounts: {
    list: FacebookAccount[]
    activeFacebookAccountId: string
    isLoading: boolean
  }
  isOpenAddChannel: boolean
  linkedinAccounts: {
    list: LinkedinAccount[]
    activeLinkedinAccountId: string
    isLoading: boolean
  }
  error: HttpErrorResponse
}

const initialState: State = {
  facebookAccounts: {
    list: [],
    activeFacebookAccountId: null,
    isLoading: false,
  },
  isOpenAddChannel: false,
  linkedinAccounts: {
    list: [],
    activeLinkedinAccountId: null,
    isLoading: false,
  },
  error: null,
}

const setFacebookAccountsLoading = (state): State => ({
  ...state,
  facebookAccounts: {
    ...state.facebookAccounts,
    isLoading: true,
  },
})

const setIntegrationsError = (state, action): State => ({
  ...state,
  isLoading: false,
  error: action.payload,
})

const clearIntegrationsError = (state): State => ({
  ...state,
  error: null,
})

const setRefreshedFacebookAccount = (state, action) => ({
  ...state,
  facebookAccounts: {
    ...state.facebookAccounts,
    isLoading: false,
    list: state.facebookAccounts.list.map((i: FacebookAccount) =>
      i._id === action.payload.data._id ? action.payload.data : i,
    ),
  },
})

const setAddedFacebookAccount = (state, action) => ({
  ...state,
  isOpenAddChannel: true,
  facebookAccounts: {
    ...state.facebookAccounts,
    isLoading: false,
    list: state.facebookAccounts.list.concat({ ...action.payload.data }),
  },
})

const setActiveFacebookAccount = (state, action): State => ({
  ...state,
  facebookAccounts: {
    ...state.facebookAccounts,
    activeFacebookAccountId: action._id,
  },
})

const setLoadedFacebookAccounts = (state, action): State => ({
  ...state,
  facebookAccounts: {
    ...state.facebookAccounts,
    isLoading: false,
    list: [...action.payload.data],
  },
})

const setLinkedinAccountsLoading = (state): State => ({
  ...state,
  linkedinAccounts: {
    ...state.linkedinAccounts,
    isLoading: true,
  },
})

const setRefreshedLinkedinAccount = (state, action) => ({
  ...state,
  linkedinAccounts: {
    ...state.linkedinAccounts,
    isLoading: false,
    list: state.linkedinAccounts.list.map((i: LinkedinAccount) =>
      i._id === action.payload.data._id ? action.payload.data : i,
    ),
  },
})

const setAddedLinkedinAccount = (state, action) => ({
  ...state,
  isOpenAddChannel: true,
  linkedinAccounts: {
    ...state.linkedinAccounts,
    isLoading: false,
    list: state.linkedinAccounts.list.concat({ ...action.payload.data }),
  },
})

const setActiveLinkedinAccount = (state, action): State => ({
  ...state,
  linkedinAccounts: {
    ...state.linkedinAccounts,
    activeLinkedinAccountId: action._id,
  },
})

const setLoadedLinkedinAccounts = (state, action): State => ({
  ...state,
  linkedinAccounts: {
    ...state.linkedinAccounts,
    isLoading: false,
    list: [...action.payload.data],
  },
})

const _integrationReducer = createReducer(
  initialState,

  on(IntegrationActions.clearIntegrationErrors, clearIntegrationsError),

  /**
   * Facebook accounts
   */

  on(
    IntegrationActions.loadFacebookAccounts,
    IntegrationActions.storeFacebookAccount,
    IntegrationActions.deleteFacebookAccount,
    setFacebookAccountsLoading,
  ),

  on(
    IntegrationActions.loadFacebookAccountsError,
    IntegrationActions.makePrimaryFacebookAccountError,
    IntegrationActions.refreshFacebookAccountError,
    IntegrationActions.storeFacebookAccountError,
    IntegrationActions.deleteFacebookAccountError,
    setIntegrationsError,
  ),

  on(IntegrationActions.loadFacebookAccountsSuccess, setLoadedFacebookAccounts),

  on(IntegrationActions.refreshFacebookAccountSuccess, setRefreshedFacebookAccount),

  on(IntegrationActions.setActiveFacebookAccount, setActiveFacebookAccount),

  on(IntegrationActions.storeFacebookAccountSuccess, setAddedFacebookAccount),

  /**
   * LinkedIn accounts
   */

  on(
    IntegrationActions.loadLinkedinAccounts,
    IntegrationActions.storeLinkedinAccount,
    IntegrationActions.deleteLinkedinAccount,
    setLinkedinAccountsLoading,
  ),

  on(
    IntegrationActions.loadLinkedinAccountsError,
    IntegrationActions.makePrimaryLinkedinAccountError,
    IntegrationActions.refreshLinkedinAccountError,
    IntegrationActions.storeLinkedinAccountError,
    IntegrationActions.deleteLinkedinAccountError,
    setIntegrationsError,
  ),

  on(IntegrationActions.loadLinkedinAccountsSuccess, setLoadedLinkedinAccounts),

  on(IntegrationActions.refreshLinkedinAccountSuccess, setRefreshedLinkedinAccount),

  on(IntegrationActions.setActiveLinkedinAccount, setActiveLinkedinAccount),

  on(IntegrationActions.storeLinkedinAccountSuccess, setAddedLinkedinAccount),
)

export function integrationReducer(state: State, action: Action): State {
  return _integrationReducer(state, action)
}
