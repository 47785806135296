import { createAction, props } from '@ngrx/store'

import { JsonResponse } from '~core/models/json-response.model'
import { FacebookAccount, FacebookAccountRequest } from '~features/integrations/models/facebook-account.model'
import { createHTTPActions } from '~store/utils.actions'

export const [loadFacebookAccounts, loadFacebookAccountsSuccess, loadFacebookAccountsError] = createHTTPActions<
  void,
  JsonResponse<FacebookAccount[]>
>('[Integrations] Load Facebook Accounts')

export const setActiveFacebookAccount = createAction(
  '[Integrations] Set Active Facebook Account',
  props<{ _id: string }>(),
)

export const [makePrimaryFacebookAccount, makePrimaryFacebookAccountSuccess, makePrimaryFacebookAccountError] =
  createHTTPActions<{ _id: string }>('[Integrations] Make Primary Facebook Account')

export const [refreshFacebookAccount, refreshFacebookAccountSuccess, refreshFacebookAccountError] = createHTTPActions<
  FacebookAccountRequest,
  JsonResponse<FacebookAccount>
>('[Integrations] Refresh Facebook Account')

export const [storeFacebookAccount, storeFacebookAccountSuccess, storeFacebookAccountError] = createHTTPActions<
  FacebookAccountRequest,
  JsonResponse<FacebookAccount>
>('[Integrations] Store Facebook Account')

export const [deleteFacebookAccount, deleteFacebookAccountSuccess, deleteFacebookAccountError] = createHTTPActions<
  { _id: string },
  JsonResponse<{ _id: string }>
>('[Integrations] Delete Facebook Account')
