import { InjectionToken } from '@angular/core'
import Rollbar from 'rollbar'

import { environment } from '~env'

const rollbarConfig: Rollbar.Configuration = {
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: environment.stage,
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig)
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar')
