export const getShortNumber = (number: number, explicitSign = false) => {
  if (isNaN(number)) return number // will only work value is a number
  if (number === null) return '-'
  if (number === 0) return 0
  let abs = Math.abs(number)
  const rounder = Math.pow(10, 1)
  const isNegative = number < 0 // will also work for Negetive numbers
  let key = ''

  const powers = [
    { key: 'Q', value: Math.pow(10, 15) },
    { key: 'T', value: Math.pow(10, 12) },
    { key: 'B', value: Math.pow(10, 9) },
    { key: 'M', value: Math.pow(10, 6) },
    { key: 'K', value: 1000 },
  ]

  let needsFormatting = true
  for (let i = 0; i < powers.length; i++) {
    let reduced = abs / powers[i].value
    reduced = Math.round(reduced * rounder) / rounder
    if (reduced >= 1) {
      abs = reduced
      key = powers[i].key
      needsFormatting = false
      break
    }
  }

  return (
    (isNegative ? '-' : explicitSign ? '+' : '') +
    (needsFormatting && !Number.isInteger(abs) ? abs.toFixed(2) : abs) +
    key
  )
}
