import { CdkPortalOutletAttachedRef, ComponentPortal, ComponentType } from '@angular/cdk/portal'
import { ComponentRef, Injectable, Signal, computed, inject, signal } from '@angular/core'
import { Store } from '@ngrx/store'

import { setOpenDrawer } from '~core/store/ui/ui.actions'
import logger from '~core/utils/logger'

type DrawerComponent<T> = {
  component: ComponentPortal<T>
  inputs: Record<string, unknown>
}

@Injectable({
  providedIn: 'root',
})
export class DrawerService<T> {
  #initialDrawerPortal = {
    component: undefined,
    inputs: {},
  }
  readonly #drawerPortal = signal<DrawerComponent<T>>(this.#initialDrawerPortal)
  readonly #store = inject(Store)

  get drawerPortal(): Signal<ComponentPortal<T>> {
    // expose a non writable signal
    return computed(() => this.#drawerPortal().component)
  }

  setDrawerContent(component: ComponentType<T>, inputs: Record<string, unknown> = {}) {
    this.#drawerPortal.set({ component: new ComponentPortal(component), inputs })
  }

  initComponent(componentRef: CdkPortalOutletAttachedRef) {
    // we need to set the component inputs after the portal is set
    // pass this function to (attached) event in portal outlet
    for (const [key, value] of Object.entries(this.#drawerPortal().inputs)) {
      const ref = componentRef as ComponentRef<T>
      ref.setInput(key, value)
    }
  }

  clearDrawerContent() {
    this.#drawerPortal.set(this.#initialDrawerPortal)
  }

  openDrawer() {
    this.#store.dispatch(setOpenDrawer({ payload: true }))
  }

  closeDrawer() {
    this.#store.dispatch(setOpenDrawer({ payload: false }))
    this.clearDrawerContent()
  }
}
