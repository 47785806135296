import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { ApiService } from '~core/services/api.service'
import { AdListRequest } from '~features/facebook-ad-account/components/ad-list/ad-list.component'
import { AdListItem } from '~features/facebook-ad-account/views/ads/ad-list-item.model'

@Injectable({
  providedIn: 'root',
})
export class FacebookAdAccountService {
  constructor(private apiService: ApiService) {}

  getAd(accountId: string, ad: string) {
    return this.apiService.get(`/v1.0/accounts/facebook-ad-account/${accountId}/content/ads/${ad}`)
  }

  static getAdStatusImgUrl(ad: AdListItem) {
    switch (ad.status) {
      case 'active':
        return '/assets/svg-icons/bullet-success.svg'
      case 'learning':
        return '/assets/svg-icons/bullet-warning.svg'
      case 'stopped':
        return '/assets/svg-icons/bullet-danger.svg'
    }
  }

  getAds(projectId: string, filters: AdListRequest) {
    const params = new HttpParams({ fromObject: filters })
    return this.apiService.get(`/v1.1/projects/${projectId}/facebook-ad-account/ads`, params)
  }
}
