import { createSelector } from '@ngrx/store'
import * as R from 'ramda'

import { Channel } from '~features/projects/models/channel.model'

import { selectIntegrations } from '.'
import * as fromIntegrations from '../integration.reducer'

const selectLinkedinAccountsObject = createSelector(
  selectIntegrations,
  (i: fromIntegrations.State) => i.linkedinAccounts,
)

export const selectActiveLinkedinAccountId = createSelector(
  selectLinkedinAccountsObject,
  (linkedinAccounts) => linkedinAccounts.activeLinkedinAccountId,
)

export const selectLinkedinAccounts = createSelector(
  selectLinkedinAccountsObject,
  (linkedinAccounts) => linkedinAccounts.list,
)

export const selectLinkedinAccountsCount = createSelector(
  selectLinkedinAccounts,
  (linkedinAccounts) => linkedinAccounts.length,
)

export const selectHasLinkedinAccounts = createSelector(selectLinkedinAccountsCount, (count) => count > 0)

const selectChannelsFromLinkedinAccount = (a): Channel[] => a.organizations

export const selectChannelsFromLinkedinAccounts = createSelector(selectLinkedinAccounts, (linkedinAccounts) =>
  R.flatten(linkedinAccounts.map(selectChannelsFromLinkedinAccount)),
)

export const selectPartiallyUnauthorizedLinkedinAccountIds = createSelector(
  selectChannelsFromLinkedinAccounts,
  (channels) => channels.filter((c) => c.is_account_authorized && !c.is_authorized).map((c) => c.linkedin_account_id),
)

export const selectFullyUnauthorizedLinkedinAccounts = createSelector(selectLinkedinAccounts, (linkedinAccounts) =>
  linkedinAccounts.filter((f) => !f.is_authorized),
)

export const selectHasUnautorizedLinkedinAccounts = createSelector(
  selectFullyUnauthorizedLinkedinAccounts,
  (linkedinAccounts) => R.not(R.isEmpty(linkedinAccounts)),
)

export const selectPartiallyUnauthorizedLinkedinAccounts = createSelector(
  selectLinkedinAccounts,
  selectPartiallyUnauthorizedLinkedinAccountIds,
  (linkedinAccounts, ids) => R.filter((f) => R.includes(f._id, ids), linkedinAccounts),
)

export const selectAllUnauthorizedLinkedinAccounts = createSelector(
  selectFullyUnauthorizedLinkedinAccounts,
  selectPartiallyUnauthorizedLinkedinAccounts,
  (fullyUnauthorized, partiallyUnauthorized) => fullyUnauthorized.concat(partiallyUnauthorized),
)

export const selectAuthorizedLinkedinAccounts = createSelector(selectLinkedinAccounts, (linkedinAccounts) =>
  linkedinAccounts.filter((f) => f.is_authorized),
)

export const selectHasAutorizedLinkedinAccounts = createSelector(
  selectAuthorizedLinkedinAccounts,
  (linkedinAccounts) => !!linkedinAccounts.length,
)

export const selectIsLoadingLinkedinAccounts = createSelector(
  selectLinkedinAccountsObject,
  (linkedinAccounts) => linkedinAccounts.isLoading,
)

export const selectFirstEligibleLinkedinAccount = createSelector(
  selectLinkedinAccounts,
  selectActiveLinkedinAccountId,
  (linkedinAccounts, activeLinkedinAccountId) => {
    const activeLinkedinAccount = linkedinAccounts.find((f) => f._id === activeLinkedinAccountId)
    return activeLinkedinAccount && activeLinkedinAccount.is_authorized
      ? activeLinkedinAccount
      : linkedinAccounts.find((f) => f.is_authorized) || null
  },
)

export const selectFirstEligibleLinkedinAccountId = createSelector(
  selectFirstEligibleLinkedinAccount,
  (linkedinAccount) => linkedinAccount._id,
)
