import { createAction, props } from '@ngrx/store'

import { JsonResponse } from '~core/models/json-response.model'
import { LinkedinAccount, LinkedinAccountRequest } from '~features/integrations/models/linkedin-account.model'
import { createHTTPActions } from '~store/utils.actions'

export const [loadLinkedinAccounts, loadLinkedinAccountsSuccess, loadLinkedinAccountsError] = createHTTPActions<
  void,
  JsonResponse<LinkedinAccount[]>
>('[Integrations] Load Linkedin Accounts')

export const setActiveLinkedinAccount = createAction(
  '[Integrations] Set Active Linkedin Account',
  props<{ _id: string }>(),
)

export const [makePrimaryLinkedinAccount, makePrimaryLinkedinAccountSuccess, makePrimaryLinkedinAccountError] =
  createHTTPActions<{ _id: string }>('[Integrations] Make Primary Linkedin Account')

export const [refreshLinkedinAccount, refreshLinkedinAccountSuccess, refreshLinkedinAccountError] = createHTTPActions<
  LinkedinAccountRequest,
  JsonResponse<LinkedinAccount>
>('[Integrations] Refresh Linkedin Account')

export const [storeLinkedinAccount, storeLinkedinAccountSuccess, storeLinkedinAccountError] = createHTTPActions<
  LinkedinAccountRequest,
  JsonResponse<LinkedinAccount>
>('[Integrations] Store Linkedin Account')

export const [deleteLinkedinAccount, deleteLinkedinAccountSuccess, deleteLinkedinAccountError] = createHTTPActions<
  { _id: string },
  JsonResponse<{ _id: string }>
>('[Integrations] Delete Linkedin Account')
