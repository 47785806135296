import * as R from 'ramda'

import { Plan } from '~core/models/plan.model'
import { Channel } from '~features/projects/models/channel.model'
import { Project } from '~features/projects/models/project.model'

export interface PaymentCard {
  card_brand: string
  card_exp_month: number
  card_exp_year: number
  card_last_four: string
}

export class User {
  _id: string
  downgrade_schedule: {
    plan: string
    billing_interval: 'month' | 'year'
    stripe_plan_id: string
  }
  downgradingFrom: string
  downgradingFromCycle: string
  downgrading_from: {
    plan: string
    billing_interval: 'month' | 'year'
    stripe_plan_id: string
  }
  email: string
  emailNotifications: {
    newsletter: boolean
    celebrations: boolean
  }
  facebookAccessToken: string
  facebookPages?: Channel[]
  facebookUserId: string
  firstName: string
  hasSubscription: boolean
  instagramAccounts?: Channel[]
  lastName: string
  onboarding: {
    firstTime: boolean
    goals: boolean
    metrics: boolean
    segments: boolean
    facebookAds: boolean
  }
  password?: string
  plan: Plan
  pm_exp_month: number
  pm_exp_year: number
  pm_last_four: string
  pm_type: string
  preferences: {
    timeFormat24?: boolean
    weekStartDay?: number
    timezone?: string
    locale?: string
  }
  primary_facebook_account_id: string
  projects: Project[]
  socialAccounts: Channel[]
  subscription_details: {
    on_trial: boolean
    next_charge_amount: number
    next_charge_discounted: number
    billing_interval: 'month' | 'year'
    next_charge_date: Date
    trial_ends_at: Date
    ends_at: Date
  }
  wasOnTrial: boolean

  constructor(user = null) {
    this.projects = []
    if (!!user) {
      Object.assign(this, user)
      if (user.projects?.length > 0) {
        this.projects = user.projects.map((project) => new Project(project))
      }
    }
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  get hasPaymentMethod(): boolean {
    return !!this.pm_type && !!this.pm_last_four
  }

  get isOnTrial(): boolean {
    return this.subscription_details.on_trial
  }

  canAccessSubscriptionRoute(route: string): boolean {
    if (route.startsWith('/user/subscription/update/preview/')) {
      return this.hasPaymentMethod && (!this.hasSubscription || !this.isSubscriptionCanceled())
    }

    switch (route) {
      case '/user/subscription/update':
        return this.hasPaymentMethod && (!this.hasSubscription || !this.isSubscriptionCanceled())
      case '/user/subscription/cancel':
        return !this.isSubscriptionCanceled()
      case '/user/subscription/reactivate':
        return this.hasSubscription && this.isSubscriptionCanceled()
      case '/user/subscription/downgrade':
        return this.isDowngrading()
    }

    return true
  }

  checkPlan(plan: Plan, billingInterval: 'month' | 'year'): boolean {
    return this.subscription_details.billing_interval === billingInterval && plan.name === this.plan.name
  }

  getChannelsCountByType(type: 'facebook-page' | 'instagram-account' | 'facebook-ad-account'): number {
    if (!this.hasProjects()) {
      return 0
    }
    let channelsCount = 0
    this.projects.forEach((project) => (channelsCount += project.getChannelsByType(type).length))
    return channelsCount
  }

  getNextChargeAmount(): number {
    return this.subscription_details.next_charge_amount / 100
  }

  getNextChargeAmountDiscounted(): number {
    return this.subscription_details.next_charge_discounted / 100
  }

  getPaymentMethod(): PaymentCard {
    return {
      card_brand: this.pm_type,
      card_last_four: this.pm_last_four,
      card_exp_month: this.pm_exp_month,
      card_exp_year: this.pm_exp_year,
    }
  }

  getProjectByChannelId(id: string): Project {
    return this.projects.find((project) => project.getChannelById(id))
  }

  getProjectById(id: string): Project {
    return this.hasProjects() ? this.projects.find((project) => project._id === id) : undefined
  }

  getProjectIndexById(id: string): number {
    return this.hasProjects() ? this.projects.findIndex((project) => project._id === id) : undefined
  }

  hasCapability(capability: string): boolean {
    if (this.isAdmin()) {
      return true
    }
    return this.plan.capabilities.includes(capability)
  }

  hasDiscount(): boolean {
    return (
      R.not(R.isNil(this.subscription_details.next_charge_discounted)) &&
      this.subscription_details.next_charge_discounted !== this.subscription_details.next_charge_amount
    )
  }

  hasProject(id: string): boolean {
    return !!this.getProjectById(id)
  }

  hasProjects(): boolean {
    return this.projects && this.projects.length > 0
  }

  isAdmin(): boolean {
    return this.plan.capabilities.includes('sudo')
  }

  isDowngradeScheduled(): boolean {
    return this.isSubscriptionCanceled() && !!this.downgrade_schedule?.stripe_plan_id
  }

  isDowngrading(): boolean {
    return !!this.downgrading_from
  }

  isFirstTime(): boolean {
    return this.onboarding.firstTime
  }

  isSubscriptionCanceled(): boolean {
    return !!this.subscription_details.ends_at
  }
}
